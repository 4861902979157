import Vue from 'vue'
import HttpMethodEnum from '@/enumeration/HttpMethodEnum'
import {Message} from 'element-ui'

class Options {
  url = ''
  params = {}
  data = {}
  showMessage = true
  success = function () {
  }
  failed = function () {
  }
}

class DefaultParams {
  language = undefined
  section = undefined
  species = undefined
  layout = undefined

  constructor (language, section, species, layout) {
    this.language = language
    this.section = section
    this.species = species
    this.layout = layout
  }
}

function afterSuccess (data, cb, showMessage = true) {
  if (showMessage && data.code !== 0) {
    const type = data.code === 0 ? 'success' : 'error'
    Message({
      dangerouslyUseHTMLString: true,
      type,
      message: data.msg
    })
  }
  cb(data)
}

function afterFailed (error, cb) {
  const message = error.response.status + '  ' + error.response.data.message
  Message.error(message)
  cb(error)
}

class BaseService {
  static adornUrl (url) {
    return Vue.prototype.$http.adornUrl(url)
  }

  static adornUrls (prefix, resource) {
    return resource.reduce((pre, resource) => {
      pre[resource] = prefix + '/' + resource
      return pre
    }, {})
  }

  submit (options) {
    const {url, params, data, success, failed} = options
    Vue.prototype.$http({
      url,
      method: HttpMethodEnum.POST,
      params: Vue.prototype.$http.adornParams(params),
      data: Vue.prototype.$http.adornData(data, false)
    }).then(({data}) => {
      afterSuccess(data, success, options.showMessage)
    }).catch(error => {
      afterFailed(error, failed)
    })
  }

  inquire (options) {
    const {url, success, failed, data, params} = options
    Vue.prototype.$http({
      url,
      method: HttpMethodEnum.GET,
      params: Vue.prototype.$http.adornParams(params),
      data: Vue.prototype.$http.adornData(data, false)
    }).then(({data}) => {
      afterSuccess(data, success, options.showMessage)
    }).catch(error => {
      afterFailed(error, failed)
    })
  }
}

export {
  Options,
  DefaultParams,
  BaseService
}
