<template>
    <div class="wrap">
        <div class="item" v-for="item in items" :key="item.id">
          <span style="display: none">{{item.description}}</span>
            <router-link v-if="isLink" :to="accurateLink(item)">
                <el-card :style="borderColor" shadow="hover" :body-style="{padding: 0}">
                    <img class="poster" :src="item.thumb">
                    <div class="content" v-if="descriptionPosition==='inside'" :style="color">
                        <el-divider></el-divider>
                        <span class="description">{{item.title || description}}</span>
                    </div>
                </el-card>
                <div class="content" v-if="descriptionPosition==='outside'" :style="color">
                    <span class="description">{{item.title || description}}</span>
                </div>
            </router-link>
            <div v-else>
                <el-card :style="borderColor" shadow="hover" :body-style="{padding: 0}">
                    <img class="poster" :src="item.thumb">
                    <div class="content" v-if="descriptionPosition==='inside'" :style="color">
                        <el-divider></el-divider>
                        <span class="description">{{item.title || description}}</span>
                    </div>
                </el-card>
                <div class="content" v-if="descriptionPosition==='outside'" :style="color">
                    <span class="description">{{item.title || description}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Card',
    props: {
      items: {
        type: Array,
        required: true
      },
      isLink: {
        type: true,
        default: false
      },
      specialLinkKey: {
        type: String
      },
      to: {
        type: String
      },
      description: {
        type: String
      },
      descriptionPosition: {
        type: String,
        default: 'inside',
        validator: s => {
          const allow = ['inside', 'outside']
          return allow.some(e => e === s)
        }
      },
      borderColor: {
        type: Object
      },
      color: {
        type: Object
      }
    },
    methods: {
      accurateLink (item) {
        if (!this.specialLinkKey) return this.to
        return {path: this.to, query: {category: item[this.specialLinkKey]}}
      }
    }
  }
</script>

<style scoped>
    .wrap {
        display: flex;
        justify-content: space-evenly;
    }

    .wrap img {
        width: 100%;
    }

    .item {
        margin-right: 20px;
        width: 260px;
        cursor: pointer;
    }

    .poster {
        width: 100%;
    }

    /*.item /deep/ .el-card__body {*/
    /*  */
    /*}*/

    .item:last-child {
        margin-right: 0;
    }

    .el-divider {
        margin: 0;
    }

    .description {
        height: 50px;
        line-height: 50px;
        display: block;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
</style>
