<template>
  <div class="panel-container">
    <e-title class="title" :content="`${title} ${sideTitle}`"></e-title>
    <el-divider></el-divider>
    <el-row v-for="(row, rowIndex) in groupedItems" :key="rowIndex" :gutter="40">
      <el-col v-for="(col, colIndex) in row" :key="colIndex" :span="24 / each">
        <video-item :href="col.videoHref" :description="col.title" :post-data="col.createTime" :poster="col.poster"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ETitle from '@/components/title'
import VideoItem from '@/views/video/components/video-item'
import {DefaultParams, Options} from '@/service/BaseService'
import LanguageUtils from '@/utils/LanguageUtils'
import {LanguageEnum, LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
import {VideoService} from '@/service/VideoService'
import {cloneDeep, merge} from 'lodash'

const service = new VideoService()
const params = new DefaultParams(
  LanguageUtils.justifyLanguage(),
  SectionEnum.VIDEO,
  SpeciesEnum.MI,
  LayoutEnum.VIDEO
)
export default {
  name: 'VideoPanel',
  components: {ETitle, VideoItem},
  props: {
    title: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    sideTitle () {
      const sideTitle = {
        [LanguageEnum.CHINESE]: '视频',
        [LanguageEnum.ENGLISH]: 'Video'
      }
      return sideTitle[LanguageUtils.justifyLanguage()]
    },
    groupedItems () {
      const items = []
      for (let i = 0; i < this.items.length; i += this.each) {
        items.push(this.items.slice(i, i + this.each))
      }
      return items
    }
  },
  methods: {
    getData () {
      const options = new Options()
      options.params = merge(cloneDeep(params), {id: this.id})
      options.success = ({items}) => {
        this.items = items || []
      }
      this.service.list(options)
    }
  },
  data () {
    // require('@vis/nerver young beach.flv')
    const each = 2
    return {
      service,
      each,
      items: []
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped>

.panel-container {
  height: 100%;
}

.title {
  padding: 10px 0 0;
  color: var(--main-theme-color);
  letter-spacing: normal;
}

/deep/ .el-divider {
  background-color: var(--main-theme-color);
}

</style>
