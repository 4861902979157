<template>
  <div class="contact-book-container">
    <e-title class="title" :content="title.field1 || ''"></e-title>
    <el-divider></el-divider>
    <div class="wrap">
      <ul>
        <li class="item" v-for="item in items">
          <img class="image" :src="item.poster">
          <div class="description-box">
            <span class="title">{{item.title}}</span>
            <span class="description">{{item.description}}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import ETitle from '@/components/title'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {AboutService} from '@/service/JoinService'
  import {TitleService} from '@/service/IndexService'
  import {cloneDeep, merge} from 'lodash'

  const service = new AboutService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.JOIN_US,
    SpeciesEnum.MI,
    LayoutEnum.JOIN_US
  )
  export default {
    name: 'ContactBook',
    components: {ETitle},
    data () {
      return {
        service,
        titleService,
        title: {},
        items: []
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = merge(cloneDeep(params), {layout: LayoutEnum.TITLE})
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || {}
        }
        this.service.list(options)
      }
    },
    created () {
      this.getTitle()
      this.getData()
    }
  }
</script>

<style scoped>

  .contact-book-container {
  }

  .title {
    padding-bottom: 0;
    letter-spacing: normal;
    color: var(--main-theme-color);
  }

  .wrap {
    width: 500px;
    margin: 0 auto;
  }

  .item {
    display: flex;
  }

  .image {
    width: 30%;
  }

  .image img {
    width: 100%;
  }

  .description-box {
    margin: auto 0;
  }

  .description-box span {
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: var(--main-theme-color);
  }

</style>
