<template>
  <div class="banner-container">
    <img :src="item.href">
    <div class="cover">
      <span>米诺焊接</span>
      <span>关注用户体验 您的焊接好朋友</span>
      <span>咨询热线： +86 18217181891</span>
    </div>
    <language></language>
  </div>
</template>

<script>
  import Language from '@/views/frame/components/language'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {BannerService} from '@/service/FrameService'

  const service = new BannerService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.BANNER,
    SpeciesEnum.MI,
    LayoutEnum.INDEX
  )
  export default {
    name: 'Banner',
    components: {Language},
    data () {
      return {
        service,
        item: {}
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.item = item || {}
        }
        this.service.first(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .banner-container {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
    position: relative;
  }

  .banner-container img {
    width: calc(var(--inner-width) * 1px);
  }

  .cover {
    display: none;
  }

</style>
