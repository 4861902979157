<template>
  <div class="brief-container">
    <e-title :content="title.field1 || ''"></e-title>
    <ul class="wrap">
      <li class="item" v-for="(item, index) in items" :key="index">
        <count-to class="number" suffix="+" :startVal="0" :endVal="item.number"></count-to>
        <span class="description">{{item.title}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  import CountTo from 'vue-count-to'
  import ETitle from '@/components/title'
  import {BriefService, TitleService} from '@/service/IndexService'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'

  const service = new BriefService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.MI,
    LayoutEnum.BRIEF
  )
  export default {
    name: 'Brief',
    components: {CountTo, ETitle},
    data () {
      return {
        service,
        titleService,
        title: {},
        items: []
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || []
        }
        this.service.list(options)
      }
    },
    created () {
      this.getTitle()
      this.getData()
    }
  }
</script>

<style scoped>
  .brief-container {
    height: 346px;
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }

  .wrap {
    display: flex;
    justify-content: space-evenly;
  }

  .item {
    margin: 0 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .number {
    color: var(--main-theme-color);
    text-align: center;
    font-size: 40px;
    font-weight: bold;
  }

  .description {
    color: gray;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
