import ServiceImpl from '@/service/ServiceImpl'
import {cloneDeep, merge} from 'lodash'
import {BaseService, Options} from '@/service/BaseService'

class CategoryService extends ServiceImpl {
  prefix = '/product/category'

  constructor () {
    super()
    if (!CategoryService.instance) {
      CategoryService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return CategoryService.instance
  }
}

class DivisionService extends ServiceImpl {
  prefix = '/product/division'

  constructor () {
    super()
    if (!DivisionService.instance) {
      DivisionService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return DivisionService.instance
  }
}

class ProductService extends ServiceImpl {
  prefix = '/product/product'

  constructor () {
    super()
    if (!ProductService.instance) {
      ProductService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return ProductService.instance
  }

  search (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.prefix + '/search')
    super.inquire(options)
  }
}

export {CategoryService, DivisionService, ProductService}
