<template>
  <div class="contact-container">
    <img :src="image">
    <ul class="wrap">
      <li class="item" v-for="(item, index) in items" :key="index">
        <i :class="item.icon"/>
        <a v-if="item.type === 'mail'" :href="`Mailto:${item.content}`">{{item.content}}</a>
        <span v-else>{{item.content}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'contact',
    data () {
      return {
        items: [
          {icon: 'iconfont icon-phone', content: '+86 - 182 1718 1891'},
          {icon: 'iconfont icon-wechat', content: 'Minoo 182 1718 1891'},
          {icon: 'iconfont icon-email', content: 'marketing@innoteccn.com', type: 'mail'}
        ],
        image: require('@img/slice.jpg')
      }
    }
  }
</script>

<style scoped>

  .contact-container {
    height: 540px;
  }

  .contact-container img {
    width: 100%;
    height: inherit;
    object-fit: cover;
    position: absolute;
    opacity: .3;
    z-index: -1;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .item {
    margin-top: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    font-weight: bold;
  }

  .item i {
    margin-right: 30px;
    width: 60px;
    display: inline-block;
    height: inherit;
    text-align: center;
    font-size: 30px;
    font-weight: normal;
    background-color: var(--main-theme-color);
    color: white;
    border-radius: 50%;
    opacity: 1 !important;
  }

  .item a {
    display: inline-block !important;
  }

</style>
