import ServiceImpl from '@/service/ServiceImpl'

class VideoService extends ServiceImpl {
  prefix = '/video/video'

  constructor () {
    super()
    if (!VideoService.instance) {
      VideoService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return VideoService.instance
  }
}

export {VideoService}
