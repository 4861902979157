import ServiceImpl from '@/service/ServiceImpl'
import {cloneDeep, merge} from 'lodash'
import {BaseService, Options} from '@/service/BaseService'

class AboutService extends ServiceImpl {
  prefix = '/join/about'

  constructor () {
    super()
    if (!AboutService.instance) {
      AboutService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return AboutService.instance
  }

  companyInfo (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.prefix + '/info')
    super.inquire(options)
  }

  sendEmail (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl('/email/send')
    super.submit(options)
  }
}

export {AboutService}
