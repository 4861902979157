class LayoutEnum {
  static INDEX = 'INDEX'
  static LAYER = 'LAYER'
  static SLOGAN = 'SLOGAN'
  static ADDRESS = 'ADDRESS'
  static DIVISION = 'DIVISION'
  static CATEGORY = 'CATEGORY'
  static COVER = 'COVER'
  static IMAGE = 'IMAGE'
  static COMPONENT = 'COMPONENT'
  static SPECIES = 'SPECIES'
  static QUALIFY = 'QUALIFY'
  static BRIEF = 'BRIEF'
  static CAPABILITY = 'CAPABILITY'
  static PARTNER = 'PARTNER'
  static PRODUCT = 'PRODUCT'
  static WHY_US = 'WHY_US'
  static VIDEO = 'VIDEO'
  static DEALER = 'DEALER'
  static JOIN_US = 'JOIN_US'
  static DOWN_BANNER = 'DOWN_BANNER'
  static TITLE = 'TITLE'
}

class SectionEnum {
  static INDEX = 'INDEX'
  static BRIEF = 'BRIEF'
  static CAPABILITY = 'CAPABILITY'
  static PRODUCT = 'PRODUCT'
  static CUSTOMIZE = 'CUSTOMIZE'
  static COMPANY = 'COMPANY'
  static CONTACT = 'CONTACT'
  static BANNER = 'BANNER'
  static FOOTER = 'FOOTER'
  static NAVIGATE = 'NAVIGATE'
  static VIDEO = 'VIDEO'
  static PARTNER = 'PARTNER'
  static DEALER = 'DEALER'
  static JOIN_US = 'JOIN_US'
  static DOWN_BANNER = 'DOWN_BANNER'
}

class PlaceEnum {
  static CUSTOMIZE = 'CUSTOMIZE'
  static CONTACT = 'CONTACT'
  static CAPABILITY = 'CAPABILITY'
  static BRIEF = 'BRIEF'
}

class SpeciesEnum {
  static OEM = 'OEM'
  static MI = 'MI'
}

class LanguageEnum {
  static CHINESE = 'CHINESE'
  static ENGLISH = 'ENGLISH'
}

export {
  LayoutEnum,
  SectionEnum,
  PlaceEnum,
  SpeciesEnum,
  LanguageEnum
}
