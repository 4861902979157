<template>
  <div class="product-detail-container">
    <div class="breadcrumb">
      <div class="crumb-content">
        <span>{{descriptionByLanguage.locationPoint}}</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>{{item.division}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{item.category}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{item.title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <span>{{descriptionByLanguage.detailPage}}</span>
      </div>
      <span style="cursor: pointer" @click="$router.go(-1)">{{descriptionByLanguage.returnLastPage}}</span>
    </div>
    <div class="content">
      <img :src="item.href || item.thumb" @click="showImage(item)" style="cursor: pointer;">
      <div class="title">
        <span>{{item.title}}</span>
        <span>{{item.title}}</span>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <td>{{tipByLanguage}}</td>
        </tr>
        </thead>
        <tbody>
        <!--        <tr>-->
        <!--          <th></th>-->
        <!--          <th>产品编号</th>-->
        <!--          <th>型号</th>-->
        <!--          <th>米数</th>-->
        <!--        </tr>-->
        <tr v-for="sub in item.races">
          <td>{{sub.code}}</td>
          <td>{{sub.title}}</td>
          <td>{{sub.description}}</td>
          <td>{{sub.sequence}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {ProductService} from '@/service/ProductService'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LanguageEnum, LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {cloneDeep, merge} from 'lodash'

  const service = new ProductService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.PRODUCT,
    SpeciesEnum.MI,
    LayoutEnum.COVER
  )
  export default {
    name: 'ProductDetail',
    computed: {
      descriptionByLanguage () {
        const description = {
          [LanguageEnum.CHINESE]: {locationPoint: '您的位置：', detailPage: '详情页', returnLastPage: '返回上一级'},
          [LanguageEnum.ENGLISH]: {locationPoint: 'Location：', detailPage: 'Detail Page', returnLastPage: 'Return'}
        }
        return description[LanguageUtils.justifyLanguage()]
      },
      tipByLanguage () {
        const tips = {
          [LanguageEnum.CHINESE]: '产品编号',
          [LanguageEnum.ENGLISH]: 'Complete Torch'
        }
        return tips[LanguageUtils.justifyLanguage()]
      }
    },
    data () {
      return {
        service,
        id: this.$route.params.id || -1,
        item: {}
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = merge(cloneDeep(params), {id: this.id})
        options.success = ({item}) => {
          this.item = item || {}
        }
        this.service.first(options)
      },
      showImage (item) {
        this.$viewerApi({
          images: [{src: item.href || item.thumb, alt: item.title}],
          options: {
            rotatable: false,
            scalable: false,
            initialViewIndex: 0,
            title: image => image['alt']
          }
        })
        // TODO: 设置CSS样式未生效， 暂时强行操作DOM元素进行设置
        document.querySelector('.viewer-title').style.fontSize = '24px'
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .product-detail-container {
    width: 100%;

    --bgc: #e5e5e5;
    --gap: 5;
  }

  .breadcrumb {
    display: flex;
    justify-content: space-between;
  }

  .breadcrumb span {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    color: #606266;
  }

  .crumb-content {
    display: flex;
  }

  .crumb-content > span:first-child {
    margin-right: 20px;
  }

  .crumb-content > span:last-child {
    margin-left: 20px;
  }


  .content img {
    width: 100%;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .title span {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: bold;
  }

  .title span:first-child {
    padding-left: 20px;
    border-left: 12px solid #36a1cf;
  }

  .title span:last-child {
    padding-right: 20px;
    border-right: 12px solid #36a1cf;
  }

  .table {
    margin-top: 60px;
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    height: 30px;
    min-width: 100px;
    line-height: 30px;
    text-align: center;
    font-size: 20px;
    background-color: var(--bgc);
    border: calc(var(--gap) * 1px) solid white;
  }

  thead > tr:last-child > td {
    font-weight: bold;
  }

  tr:nth-child(even) > td {
    background-color: white;
  }


</style>
