<template>
  <div class="product-container">
    <e-title :content="title.field1 || ''"></e-title>
    <card to="/product" special-link-key="description" :is-link="true" :items="items" :description="description"></card>
  </div>
</template>

<script>
  import Card from '@/components/card'
  import ETitle from '@/components/title'
  import {ProductService, TitleService} from '@/service/IndexService'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'

  const service = new ProductService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.MI,
    LayoutEnum.PRODUCT
  )
  export default {
    name: 'Product',
    components: {
      ETitle, Card
    },
    data () {
      return {
        service,
        titleService,
        title: {},
        description: '想了解更多',
        items: []
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || []
        }
        this.service.list(options)
      }
    },
    created () {
      this.getTitle()
      this.getData()
    }
  }
</script>

<style scoped>
  .product-container {
    height: 525px;
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }


</style>
