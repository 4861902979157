<template>
    <div class="navigate-container">
        <div class="wrap">
            <router-link class="item"
                         v-for="(item, index) in items"
                         :key="index"
                         :to="item.thumb"
                         active-class="is-active">
                {{item.title}}
            </router-link>
        </div>
    </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {NavigateService} from '@/service/FrameService'

  const service = new NavigateService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.NAVIGATE,
    SpeciesEnum.MI,
    LayoutEnum.INDEX
  )
  export default {
    name: 'Navigate',
    data () {
      return {
        service,
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || {}
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
    .navigate-container {
        background-color: var(--main-theme-color);
    }

    .wrap {
        width: calc(var(--inner-width) * 1px);
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
    }

    .item {
        width: 100%;
        min-width: 200px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: white;
        font-weight: bold;
    }

    .is-active {
        background-color: #fff;
        color: var(--main-theme-color);
    }


</style>
