<template>
  <div class="dealer-container">
    <div class="wrap">
      <e-title class="title" :content="info.title"></e-title>
      <el-divider></el-divider>
      <div class="description-box">
        <span>{{info.description}}</span>
      </div>
      <provided-panel></provided-panel>
      <div class="logo-box">
        <img :src="info.thumb">
      </div>
    </div>
    <down-banner></down-banner>
  </div>
</template>

<script>
  import ETitle from '@/components/title'
  import ProvidedPanel from '@/views/dealer/components/provided-panel'
  import {DefaultParams, Options} from '@/service/BaseService'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {DealerService} from '@/service/DealerService'
  import DownBanner from '@/views/common/down-banner'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new DealerService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.DEALER,
    SpeciesEnum.MI,
    LayoutEnum.DEALER
  )
  export default {
    name: 'Dealer',
    components: {ETitle, ProvidedPanel, DownBanner},
    data () {
      return {
        service,
        info: {}
      }
    },
    methods: {
      getInfo () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.info = item
        }
        this.service.first(options)
      }
    },
    created () {
      this.getInfo()
    }
  }
</script>

<style scoped>

  .dealer-container {

  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }

  .title {
    padding-bottom: 0;
    color: var(--main-theme-color);
  }

  /deep/ .el-divider {
    background-color: var(--main-theme-color);
  }

  .description-box {
    margin: 0 auto;
    width: 840px;
    font-size: 18px;
    font-weight: bold;
    text-indent: 2em;
  }

  .logo-box,
  .logo-box img {
    width: 100%;
  }

</style>
