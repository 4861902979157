<template>
  <div class="dealer-list-container">
    DealerList
  </div>
</template>

<script>
  export default {
    name: 'DealerList'
  }
</script>

<style scoped>

</style>
