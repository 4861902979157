<template>
  <div class="join-us-container">
    <div class="wrap">
      <contact></contact>
      <message></message>
    </div>
    <down-banner></down-banner>
  </div>
</template>

<script>
  import ETitle from '@/components/title'
  import Contact from '@/views/about/components/contact-book'
  import Dealer from '@/views/about/components/dealer-list'
  import Message from '@/views/about/components/message'
  import DownBanner from '@/views/common/down-banner'

  export default {
    name: 'JoinUs',
    components: {ETitle, Contact, Dealer, Message, DownBanner}
  }
</script>

<style scoped>

  .join-us-container {
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }


  /deep/ .el-divider {
    background-color: var(--main-theme-color);
  }

</style>
