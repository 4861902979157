<template>
  <div class="hexagon-container">
    <div class="wrap">
      <div class="image"
           :style="{
        backgroundImage: `url(${href})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '300px 300px'
      }">{{idx}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'index',
    computed: {
      height () {
        return this.width * Math.sqrt(3)
      }
    },
    props: {
      width: {
        type: Number,
        default: 100
      },
      href: {
        type: String,
        required: true
      },
      idx: {
        type: Number,
        default: 0
      }
    }
  }
</script>

<style scoped>

  .hexagon-container {
    margin-right: 20px;
    width: 200px;
    height: 324px;
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
  }

  .wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }

  .image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }


</style>
