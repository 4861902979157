import ServiceImpl from '@/service/ServiceImpl'
import {cloneDeep, merge} from 'lodash'
import {BaseService, Options} from '@/service/BaseService'

class PartnerService extends ServiceImpl {
  prefix = '/partner/partner'

  constructor () {
    super()
    if (!PartnerService.instance) {
      PartnerService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return PartnerService.instance
  }

  title (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.prefix + '/title')
    super.inquire(options)
  }
}

export {PartnerService}
