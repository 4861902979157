<template>
  <div class="slice-container">
    <el-carousel height="700px">
      <el-carousel-item v-for="(item, index) in items" :key="index">
        <img :src="item.href">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {SliceService} from '@/service/IndexService'

  const service = new SliceService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.MI,
    LayoutEnum.INDEX
  )
  export default {
    name: 'Slice',
    data () {
      return {
        service,
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || []
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .slice-container {
    width: 100%;
  }

  .slice-container img {
    width: 100%;
    vertical-align: top;
  }
</style>
