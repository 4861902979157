<template>
  <div class="product-container">
    <div class="wrap">
      <e-aside ref="aside" :items="items"></e-aside>
      <router-view class="content" :key="$route.path"></router-view>
    </div>
    <img class="footer" :src="bgc">
  </div>
</template>

<script>
  import EAside from '@/views/product/components/aside'
  import EContent from '@/views/product/components/product-list'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {CategoryService} from '@/service/ProductService'

  const service = new CategoryService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.PRODUCT,
    SpeciesEnum.MI,
    LayoutEnum.CATEGORY
  )
  export default {
    name: 'Product',
    components: {
      EAside, EContent
    },
    data () {
      return {
        service,
        items: [],
        bgc: require('@img/slice.jpg')
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || []
          const categoryId = this.$route.query.category
          if (categoryId) {
            const matched = this.items.find(e => e.title === categoryId)
            if (matched && matched.items.length > 0) {
              const path = `/product/list/${matched.items[0].id}`
              this.$router.push({path: path})
            }
          } else if (this.items.length > 0 && this.items[0].items.length > 0) {
            const path = `/product/list/${this.items[0].items[0].id}`
            this.$router.push({path: path})
          }
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .product-container {
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
    display: flex;
  }

  .wrap .content {
    margin-left: 20px;
    width: calc((var(--inner-width) - 280) * 1px);
  }

  .footer {
    margin-top: 60px;
    width: 100%;
    opacity: 0.3;
  }
</style>
