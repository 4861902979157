<template>
  <div class="provided-panel-container">
    <div class="wrap">
      <ul class="item-box">
        <span class="guide">{{guideByLanguage.provide}}</span>
        <li class="item" v-for="item in items" :key="item.id" @click="currentItem=item">
          <div class="dot" :style="{backgroundColor: `${item.color}`}"></div>
          <span class="title">{{item.title}}</span>
        </li>
        <span class="more">{{guideByLanguage.more}}</span>
      </ul>
      <div class="stage" :style="{borderColor: `${currentItem.dotColor}`}">
        <img :src="currentItem.poster">
      </div>
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LanguageEnum, LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {DealerService} from '@/service/DealerService'

  const service = new DealerService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.DEALER,
    SpeciesEnum.MI,
    LayoutEnum.DEALER
  )
  export default {
    name: 'ProvidedPanel',
    computed: {
      guideByLanguage () {
        const guide = {
          [LanguageEnum.CHINESE]: {provide: '我们还能提供什么？', more: '还有更多. . .'},
          [LanguageEnum.ENGLISH]: {provide: 'What Can We Provide?', more: 'MORE. . . '}
        }
        return guide[LanguageUtils.justifyLanguage()]
      }
    },
    data () {
      return {
        service,
        items: [],
        currentItem: {}
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || []
          this.currentItem = this.items[0] || {}
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>

  .provided-panel-container {
    width: 100%;
  }

  .wrap {
    margin: 30px auto 0;
    width: 940px;
    display: flex;
    justify-content: space-between;
  }

  .guide,
  .more {
    margin-bottom: 20px;
    display: block;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .more {
    text-align: left;
    padding-left: 70px;
  }

  .item-box {
    width: 540px;
    height: 100%;
  }

  .item {
    margin-left: 40px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }

  .dot {
    margin: auto 0;
    display: inline-block;
    height: 12px;
    width: 12px;
    background-color: var(--main-theme-color);
    border-radius: 50%;
  }

  .title {
    margin-left: 12px;
    display: inline-block;
    height: inherit;
    line-height: inherit;
  }


  .stage {
    width: 50%;
    border: 2px solid var(--main-theme-color);
    border-radius: 30px;
    overflow: hidden;
  }

  .stage img {
    width: 100%;
  }

</style>
