import Vue from 'vue'
import App from './App.vue'
import router from '@/router'

import httpRequest from '@/utils/HttpRequest'
import '@/element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@font/iconfont.css'
import '@public/css/font.css'

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
// import LanguageUtils from '@/utils/LanguageUtils'

// if (LanguageUtils.justifyLanguage() === 'ENGLISH') {
//   const dynamicImportFont = require('@/utils/dynamicImportFont')
//   dynamicImportFont()
// }

Vue.use(VueViewer)
Vue.use(VueVideoPlayer)
Vue.prototype.$http = httpRequest
Vue.config.productionTip = false

Vue.prototype.$bus = new Vue()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
