<template>
  <div class="partner-container">
    <div class="wrap">
      <e-title class="title" :content="title.field1 || ''"></e-title>
      <el-divider></el-divider>
      <partner-item v-for="item in items" :key="item.id" :item="item"></partner-item>
    </div>
    <down-banner></down-banner>
  </div>
</template>

<script>
  import ETitle from '@/components/title'
  import PartnerItem from '@/views/partner/components/partner-item'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {PartnerService} from '@/service/PartnerService'
  import DownBanner from '@/views/common/down-banner'

  const service = new PartnerService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.PARTNER,
    SpeciesEnum.MI,
    LayoutEnum.PARTNER
  )
  export default {
    name: 'Partner',
    components: {ETitle, PartnerItem, DownBanner},
    data () {
      return {
        service,
        title: '全球战略合作伙伴',
        items: []
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.service.title(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || {}
        }
        this.service.list(options)
      }
    },
    created () {
      this.getTitle()
      this.getData()
    }
  }
</script>

<style scoped>
  .partner-container {

  }

  .wrap {
    margin: 0 auto;
    width: calc(var(--inner-width) * 1px);
  }

  .title {
    padding-bottom: 0;
    font-weight: bold;
    color: var(--main-theme-color);
  }

  /deep/ .el-divider {
    background-color: var(--main-theme-color);
  }
</style>
