import ServiceImpl from '@/service/ServiceImpl'
import {cloneDeep, merge} from 'lodash'
import {BaseService, Options} from '@/service/BaseService'

class BannerService extends ServiceImpl {
  prefix = '/frame/banner'

  constructor () {
    super()
    if (!BannerService.instance) {
      BannerService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return BannerService.instance
  }

  down (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.prefix + '/down')
    super.inquire(options)
  }
}

class NavigateService extends ServiceImpl {
  prefix = '/frame/navigate'

  constructor () {
    super()
    if (!NavigateService.instance) {
      NavigateService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return NavigateService.instance
  }
}

class CopyRightService extends ServiceImpl {
  prefix = '/frame/copyright'

  constructor () {
    super()
    if (!CopyRightService) {
      CopyRightService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return CopyRightService.instance
  }
}

export {BannerService, NavigateService, CopyRightService}
