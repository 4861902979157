import {BaseService, Options} from '@/service/BaseService'
import {cloneDeep, merge} from 'lodash'

export default class ServiceImpl extends BaseService {
  resources = ['save', 'delete', 'update', 'all', 'list', 'page', 'first', 'info']
  urls = {}

  // INSERT
  save (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.save)
    super.submit(options)
  }

  // DELETE
  delete (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.delete)
    super.submit(options)
  }

  // UPDATE
  update (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.update)
    super.submit(options)
  }

  // SELECT
  all (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.all)
    super.inquire(options)
  }

  list (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.list)
    super.inquire(options)
  }

  page (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.page)
    super.inquire(options)
  }

  first (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.first)
    super.inquire(options)
  }

  info (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(`${this.urls.info}/${options.params.id}`)
    super.inquire(options)
  }
}
