import ServiceImpl from '@/service/ServiceImpl'

class DealerService extends ServiceImpl {
  prefix = '/dealer/dealer'

  constructor () {
    super()
    if (!DealerService.instance) {
      DealerService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return DealerService.instance
  }
}

export {DealerService}
