<template>
  <div class="video-container">
    <div class="wrap">
      <el-tabs tab-position="left" v-model="activeName">
        <el-tab-pane :label="item.title" v-for="item in items" :key="item.id" :name="item.title">
          <video-panel :id="item.id" :title="item.title"></video-panel>
        </el-tab-pane>
      </el-tabs>
    </div>
    <down-banner></down-banner>
  </div>
</template>

<script>
  import VideoPanel from '@/views/video/components/video-panel'
  import DownBanner from '@/views/common/down-banner'
  import {DivisionService} from '@/service/ProductService'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'

  const service = new DivisionService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.PRODUCT,
    SpeciesEnum.MI,
    LayoutEnum.DIVISION
  )
  export default {
    name: 'Video',
    components: {VideoPanel, DownBanner},
    data () {
      return {
        service,
        activeName: '',
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || []
          const category = parseInt(this.$route.query.category || '0')
          const pointer = this.items.find(e => e.id === category) || this.items[0] || {}
          this.activeName = pointer.title
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>

  .video-container {
    width: 100%;
    height: 100%;
  }

  .wrap {
    margin: 20px auto 0;
    width: calc(var(--inner-width) * 1px);
    height: 100%;
  }

  /deep/ .el-tabs__item {
    text-align: center !important;
    font-size: 20px;
  }

  /deep/ .el-tabs__item.is-active {
    color: var(--main-theme-color);
    background-color: #d4d4d4;
  }

  /deep/ .el-tabs__active-bar {
    background-color: var(--main-theme-color);
  }

</style>
