<template>
  <div class="message-container">
    <el-divider></el-divider>
    <img class="image" v-if="parseInt(slogan.sequence)===1" :src="slogan.thumb">
    <e-title class="title" :content="slogan.description"></e-title>
    <div class="form">
      <el-form ref="dataForm" :model="message" :rules="formRules">
        <el-form-item prop="name" :label="accurateLabel.name">
          <el-input v-model="message.name"></el-input>
        </el-form-item>
        <el-form-item prop="phone" :label="accurateLabel.phone">
          <el-input v-model="message.phone"></el-input>
        </el-form-item>
        <el-form-item prop="email" :label="accurateLabel.email">
          <el-input v-model="message.email"></el-input>
        </el-form-item>
        <el-form-item prop="message" :label="accurateLabel.message">
          <el-input type="textarea" v-model="message.message"></el-input>
        </el-form-item>
      </el-form>
      <el-button class="submit-button" round @click="sendEmail">{{accurateLabel.button}}</el-button>
    </div>
    <div class="company-info-box">
      <span>{{companyInfo.field1}}</span>
      <span>{{companyInfo.field2}}</span>
      <span>{{companyInfo.field3}}</span>
    </div>
  </div>
</template>

<script>
  import ETitle from '@/components/title'
  import {DefaultParams, Options} from '@/service/BaseService'
  import {LanguageEnum, LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {AboutService} from '@/service/JoinService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new AboutService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.JOIN_US,
    SpeciesEnum.MI,
    LayoutEnum.JOIN_US
  )
  export default {
    name: 'Message',
    components: {ETitle},
    computed: {
      accurateLabel () {
        const labels = {
          [LanguageEnum.ENGLISH]: {
            name: 'Name', phone: 'Cellphone', email: 'E-mail', message: 'Message', button: 'Submit'
          },
          [LanguageEnum.CHINESE]: {
            name: '留言', phone: '电话', email: '邮箱', message: '留言', button: '提  交'
          }
        }
        return labels[LanguageUtils.justifyLanguage()]
      }
    },
    data () {
      return {
        service,
        logo: require('@img/join-us-logo.jpg'),
        message: {name: '', phone: '', email: '', message: ''},
        formRules: {
          name: [{required: true, trigger: 'blur'}],
          phone: [{required: true, trigger: 'blur'}],
          email: [{required: true, trigger: 'blur'}],
          message: [{required: true, trigger: 'blur'}]
        },
        companyInfo: {},
        slogan: {}
      }
    },
    methods: {
      getSlogan () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.slogan = item
        }
        this.service.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.companyInfo = item
        }
        this.service.companyInfo(options)
      },
      sendEmail () {
        this.$refs.dataForm.validate(valid => {
          if (!valid) {
            const message = {
              [LanguageEnum.ENGLISH]: 'Please fulfill info before you submit.',
              [LanguageEnum.CHINESE]: '在提交前请填写完整表单.'
            }
            this.$message({
              message: message[LanguageUtils.justifyLanguage()],
              type: 'warning'
            })
          } else {
            const options = new Options()
            options.data = this.message
            options.success = () => {
              this.$message({message: 'Email has been send.', type: 'success'})
            }
            this.service.sendEmail(options)
          }
        })
      }
    },
    created () {
      this.getSlogan()
      this.getData()
    }
  }
</script>

<style scoped>

  .title {
    color: var(--main-theme-color);
    letter-spacing: normal;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  .form {
    margin: 0 auto;
    width: 500px;
  }

  .submit-button {
    background-color: var(--main-theme-color);
    font-weight: bold;
    color: white;
  }

  .company-info-box {
    width: 500px;
    margin: 60px auto 80px;
  }

  .company-info-box span {
    display: block;
    font-size: 18px;
    font-weight: bold;
  }
</style>
