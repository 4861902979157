<template>
  <div class="frame-container" :class="fontFamilyByLanguage">
    <banner></banner>
    <navigate></navigate>
    <quick-menu position="bottom-right"
                backgroundColor="#EB6100"></quick-menu>
    <router-view></router-view>
  </div>
</template>

<script>
  import Banner from '@/views/frame/components/banner'
  import Navigate from '@/views/frame/components/navigate'
  import QuickMenu from '@/views/frame/components/quick-menu'
  import LanguageUtils from '@/utils/LanguageUtils'

  export default {
    name: 'Index',
    components: {
      Banner, Navigate, QuickMenu
    },
    computed: {
      fontFamilyByLanguage () {
        return LanguageUtils.justifyLanguage() === 'ENGLISH' ? 'english-font-family' : 'chinese-font-family'
      }
    }
  }
</script>

<style scoped>
  .english-font-family {
    /*font-family: "Hobo Bold" !important;*/
    font-family: "Arial Rounded MT Bold" !important;
  }

  .chinese-font-family {
    /*font-family: "Source Han Sans CN" !important;*/
    font-family: "Arial Rounded MT Bold" !important;
  }

</style>
