<template>
  <span class="title-container">{{content}}</span>
</template>

<script>
  export default {
    name: 'Title',
    props: {
      content: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>
  .title-container {
    padding: 30px 0;
    display: block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 40px;
    letter-spacing: .2em;
  }
</style>
