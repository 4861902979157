<template>
  <div class="video-item-container">
    <div class="video-box">
      <video-player class="video-player vjs-custom-skin" :options="videoOptions"></video-player>
    </div>
    <div class="description-box">
      <div class="description">{{ description }}</div>
      <div class="post-data">{{ dateFormatter(postData) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoItem',
  props: {
    href: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    postData: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      videoOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'metadata',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          type: '',
          src: this.href || ''
        }],
        poster: this.poster,
        notSupportedMessage: '此视频暂无法播放，请稍后再试!',
        controlBar: {
          timeDivider: true,
          durationDisplay: true
        }
      }
    }
  },
  methods: {
    dateFormatter (date = '') {
      return date.split(' ')[0]
    }
  }
}
</script>

<style scoped>
.video-item-container {
  margin-bottom: 24px;
}

.video-box {
  width: 100%;
}

.description-box {
  margin: auto 0;
  height: 100%;
  padding-left: 20px;
  font-size: 30px;
}
</style>
