<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/*初始化样式*/
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form,
fieldset, legend, button, input, textarea, th, td {
  margin: 0;
  padding: 0;
}

body {
  min-width: calc(var(--inner-width) * 1px + 20px)
}

body, button, input, select, textarea {
  font: 12px/1.5 tahoma, Arial, \5b8b\4f53;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%
}

address, cite, dfn, em, var {
  font-style: normal;
}

small {
  font-size: 12px;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

legend {
  color: #000;
}

fieldset, img {
  border: 0;
}

button, input, select, textarea {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*样式初始化结束*/


:root {
  --white: white;
  --main-theme-color: #EB6100;
  --title-theme-coilor: #027cc7;
  --radius: 10px;
  --board-title-color: #dddddd;
}


</style>
