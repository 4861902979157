<template>
  <div class="search-container">
    <div class="empty" v-if="items.length===0">
      <span>[ {{keyword}} ] {{emptyString}}</span>
    </div>
    <div class="items" v-else>
      <router-link class="item" v-for="item in items" :key="item.id" :to="{path: `/product/detail/${item.id}`}">
        <el-card class="card" shadow="hover" :body-style="{padding:0}">
          <img class="cover" :src="item.thumb">
          <span class="title">
            {{item.title}}</span>
          <el-divider class="divider-color divider-width"></el-divider>
          <ul class="content">
            <li class="description" v-for="des in item.descriptions" :key="des.id">
              <div class="content-wrap">
                <img :src="des.thumb" :style="{width: des.title + 'px', height: des.title + 'px'}">
                <div class="span">{{des.description}}</div>
              </div>
            </li>
          </ul>
        </el-card>
      </router-link>
    </div>
  </div>
</template>

<script>
  import Card from '@/components/card'
  import {LanguageEnum, LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {DefaultParams, Options} from '@/service/BaseService'
  import {ProductService} from '@/service/ProductService'
  import {cloneDeep, merge} from 'lodash'
  import EventBusNamespace from '@/bus'

  const service = new ProductService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.PRODUCT,
    SpeciesEnum.MI,
    LayoutEnum.COVER
  )
  export default {
    name: 'ProductSearch',
    components: {Card},
    computed: {
      emptyString () {
        const words = {
          [LanguageEnum.ENGLISH]: 'Sorry, There\'s Nothing',
          [LanguageEnum.CHINESE]: '没有搜索到您查询的产品。'
        }
        return words[LanguageUtils.justifyLanguage()]
      }
    },
    // watch: {
    //   '$route' (to, from) {
    //     this.getData()
    //   }
    // },
    data () {
      return {
        service,
        keyword: this.$route.query.searchKeyword,
        // items: Mock.items
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = merge(cloneDeep(params), {keyword: this.keyword})
        options.success = ({items}) => {
          this.items = items || []
          this.items.forEach(e => {
            e.description = e.description.split('\n')
          })
        }
        this.service.search(options)
      }
    },
    mounted () {
      this.$bus.$on(EventBusNamespace.SEARCH, keyword => {
        this.keyword = keyword
        this.getData()
      })
    },
    beforeDestroy () {
      this.$bus.$off(EventBusNamespace.SEARCH)
    }
  }
</script>

<style scoped>
  a {
    text-decoration: none;
  }

  .search-container {
    width: 100%;
  }

  .empty {
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 26px;
  }

  .items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .card {
    margin-bottom: 20px;
    width: 300px;
    border-color: var(--main-theme-thin-color);
  }

  .card:hover {
    box-shadow: 0 2px 12px 0 rgb(245 178 141) !important;
  }

  .divider-color {
    margin: 0 auto;
    background-color: var(--main-theme-thin-color);
  }

  .divider-width {
    width: 80%;
    height: 2px;
    margin: 12px auto;
  }


  .cover {
    width: 300px;
  }


  .title {
    width: 100%;
    margin: 0 auto;
    display: inline-block;
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
  }


  .description {
    /*padding-left: 10%;*/
    margin-top: 6px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .content {
    width: inherit;
    margin: 20px auto;

  }

  .description .content-wrap {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

  }

  .description img {
    margin-right: 12px;
  }

  .description i {
    width: 20%;
    display: inline-block;
  }

  .description .span {
    font-size: 16px;
    font-weight: bold;
  }

</style>
