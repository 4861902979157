<template>
  <div class="down-banner-container">
    <img style="width: 100%; opacity: .3" :src="item.thumb">
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {BannerService} from '@/service/FrameService'

  const service = new BannerService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.DOWN_BANNER,
    SpeciesEnum.MI,
    LayoutEnum.DOWN_BANNER
  )
  export default {
    name: 'DownBanner',
    data () {
      return {
        service,
        item: {}
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.item = item
        }
        this.service.down(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .down-banner-container {
    margin-top: 60px;
  }
</style>
