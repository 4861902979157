<template>
  <div class="partner-item-container">
    <div class="wrap">
      <div class="poster">
        <img  :src="item.poster" :alt="item.title">
      </div>
      <div class="description-box">
        <span class="title">{{item.title}}</span>
        <span class="description">{{item.description}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PartnerItem',
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
  .partner-item-container {
    width: 100%;
    /*height: 340px;*/
    /*margin-bottom: 24px;*/
    margin: 168px 0;
  }

  .wrap {
    margin-left: 40px;
    display: flex;
    justify-content: space-between;
  }

  .poster {
    width: 48%;
  }

  .poster img {
    width: 100%;
    object-fit: contain;
  }

  .description-box {
    width: 50%;
  }

  .description-box span {
    display: block;
    font-size: 24px;
    font-weight: bold;

  }


</style>
