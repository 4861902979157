import ServiceImpl from '@/service/ServiceImpl'
import {Options} from '@/service/BaseService'
import {cloneDeep, merge} from 'lodash'

class SliceService extends ServiceImpl {
  prefix = '/index/slice'

  constructor () {
    super()
    if (!SliceService.instance) {
      SliceService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return SliceService.instance
  }
}

class BriefService extends ServiceImpl {
  prefix = '/index/brief'

  constructor () {
    super()
    if (!BriefService.instance) {
      BriefService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return BriefService.instance
  }
}

class ProductService extends ServiceImpl {
  prefix = '/index/product'

  constructor () {
    super()
    if (!ProductService.instance) {
      ProductService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return ProductService.instance
  }
}

class VideoService extends ServiceImpl {
  prefix = '/index/video'

  constructor () {
    super()
    if (!VideoService.instance) {
      VideoService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return VideoService.instance
  }
}

class PartnerService extends ServiceImpl {
  prefix = '/index/partner'

  constructor () {
    super()
    if (!PartnerService.instance) {
      PartnerService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return PartnerService.instance
  }

  description (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = ServiceImpl.adornUrl(this.prefix + '/description')
    super.inquire(options)
  }
}

class DealerService extends ServiceImpl {
  prefix = '/index/dealer'

  constructor () {
    super()
    if (!DealerService.instance) {
      DealerService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return DealerService.instance
  }
}

class JoinService extends ServiceImpl {
  prefix = '/index/join'

  constructor () {
    super()
    if (!JoinService.instance) {
      JoinService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return JoinService.instance
  }
}

class TitleService extends ServiceImpl {
  prefix = '/index/title'

  constructor () {
    super()
    if (!TitleService.instance) {
      TitleService.instance = this
    }
    this.urls = TitleService.adornUrls(this.prefix, this.resources)
    return TitleService.instance
  }
}

export {
  SliceService,
  BriefService,
  ProductService,
  VideoService,
  PartnerService,
  DealerService,
  JoinService,
  TitleService
}
