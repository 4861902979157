<template>
  <div class="home-container">
    <slice></slice>
    <brief></brief>
    <product></product>
    <e-video></e-video>
    <partner></partner>
    <dealer></dealer>
    <join-us></join-us>
    <contact></contact>
    <copy-right></copy-right>
  </div>
</template>

<script>
  import Slice from '@/views/home/components/slice'
  import Brief from '@/views/home/components/brief'
  import Product from '@/views/home/components/product'
  import EVideo from '@/views/home/components/video'
  import Partner from '@/views/home/components/partner'
  import Dealer from '@/views/home/components/dealer'
  import JoinUs from '@/views/home/components/join-us'
  import Contact from '@/views/home/components/contact'
  import CopyRight from '@/views/home/components/copy-right'
  export default {
    name: 'Home',
    components: {
      Slice, Brief, Product, EVideo, Partner, Dealer, JoinUs, Contact, CopyRight
    }
  }
</script>

<style scoped>
</style>
