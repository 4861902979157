<template>
  <div class="partner-container">
    <!--    <img class="bgc" :src="bgcImg">-->
    <router-link :to="{path: '/partner'}">
      <div class="wrap">
        <e-title class="title" :content="title.field1 || ''"></e-title>
        <e-title class="description" :content="description.description || ''"></e-title>
        <div class="box">
          <div class="items" v-if="loaded">
            <hexagon class="item" v-for="(item, index) in images" :key="index" :href="item.href" :idx="index"></hexagon>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
  import ETitle from '@/components/title'
  import Hexagon from '@/components/hexagon'
  import {PartnerService, TitleService} from '@/service/IndexService'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'

  const service = new PartnerService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.MI,
    LayoutEnum.PARTNER
  )
  // TODO: description 需要在后台修改
  export default {
    name: 'Partner',
    components: {
      ETitle, Hexagon
    },
    computed: {
      images () {
        return this.items.concat(this.items)
      }
    },
    data () {
      return {
        service,
        titleService,
        title: {},
        description: {},
        bgcImg: require('@img/slice.jpg'),
        loaded: false,
        items: []
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getDescription () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.description = item || {}
        }
        this.service.description(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || []
          this.loaded = true
        }
        this.service.list(options)
      }
    },
    created () {
      this.getTitle()
      this.getDescription()
      this.getData()
    }
  }
</script>

<style scoped>

  .partner-container {
    margin-bottom: 200px;
  }

  .partner-container {
    height: 525px;
  }

  .bgc {
    width: 100%;
    height: inherit;
    position: absolute;
    opacity: .3;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    height: inherit;
    margin: 0 auto;
    overflow: hidden;
  }

  .title {
    font-size: 40px;
  }

  .description {
    height: 200px;
    font-size: 26px;
    letter-spacing: normal;
    text-indent: 2em;
    text-align: left;
  }

  .box {
    width: calc(var(--inner-width) * 1px);
    height: 300px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }


  .items {
    height: 300px;;
    animation: move 20s linear infinite;
    -webkit-animation: move 20s linear infinite;
    position: absolute;
    display: flex;

  }

  .item {
    height: 260px;;
    display: inline-block;
    margin: 0 24px;
  }

  @keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @-webkit-keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }


</style>
