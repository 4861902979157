import Vue from 'vue'
import VueRouter from 'vue-router'
import * as ld from 'lodash'
// 懒加载
// const MainFrame = () => import(/* webpackChunkName: "group-frame" */ '@/views/frame/index')
// const Home = () => import(/* webpackChunkName: "group-home" */ '@/views/home/index')

import MainFrame from '@/views/frame/index'
import Home from '@/views/home/index'
import Product from '@/views/product/index'
import ProductList from '@/views/product/components/product-list'
import ProductDetail from '@/views/product/components/product-detail'
import ProductSearch from '@/views/product/components/product-search'
import Video from '@/views/video/index'
import Partner from '@/views/partner/index'
import Dealer from '@/views/dealer/index'
import JoinUs from '@/views/about/index'
import LanguageUtils from '@/utils/LanguageUtils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainFrame,
    redirect: {name: 'Home'},
    children: [
      {
        path: '/index',
        component: Home,
        name: 'Home',
        meta: {
          title: {
            en: 'MINOO TORCH',
            zh: '米诺焊接'
          },
          keywords: {
            en: 'MINOO TORCH，WELDING，MIG MAG TORCH，TIG TORCH，CUTTING GUN，PLASMA TORCH，ROBOT',
            zh: '米诺焊枪，二氧化碳气体保护焊，氩弧焊，氩弧焊，等离子切割枪，焊枪，焊接'
          },
          description: {
            en: 'MINOO TORCH，WELDING，MIG MAG TORCH，TIG TORCH，CUTTING GUN，PLASMA TORCH，ROBOT',
            zh: '米诺焊枪，二氧化碳气体保护焊，氩弧焊，氩弧焊，等离子切割枪，焊枪，焊接'
          }
        }
      },
      {
        path: '/product',
        component: Product,
        name: 'Product',
        meta: {title: '产品'},
        children: [
          {
            path: '/product/list/:id',
            component: ProductList,
            name: 'ProductList',
            meta: {
              title: {
                en: '',
                zh: '米诺·产品列表'
              },
              keywords: {
                en: 'MINOO TORCH，WELDING，MIG MAG TORCH，TIG TORCH，CUTTING GUN，PLASMA TORCH，ROBOT',
                zh: '米诺焊枪，二氧化碳气体保护焊枪，氩弧枪，氩弧焊，等离子切割枪，焊接，数显枪'
              },
              description: {
                en: 'MINOO TORCH，WELDING，MIG MAG TORCH，TIG TORCH，CUTTING GUN，PLASMA TORCH，ROBOT',
                zh: '米诺焊枪，二氧化碳气体保护焊枪，氩弧枪，氩弧焊，等离子切割枪，焊接，数显枪'
              }
            }
          },
          {
            path: '/product/detail/:id',
            component: ProductDetail,
            name: 'ProductDetail',
            meta: {
              title: {
                en: '',
                zh: '米诺·产品列表'
              },
              keywords: {
                en: 'MINOO TORCH，WELDING，MIG MAG TORCH，TIG TORCH，CUTTING GUN，PLASMA TORCH，ROBOT',
                zh: '米诺焊枪，二氧化碳气体保护焊枪，氩弧枪，氩弧焊，等离子切割枪，焊接，数显枪'
              },
              description: {
                en: 'MINOO TORCH，WELDING，MIG MAG TORCH，TIG TORCH，CUTTING GUN，PLASMA TORCH，ROBOT',
                zh: '米诺焊枪，二氧化碳气体保护焊枪，氩弧枪，氩弧焊，等离子切割枪，焊接，数显枪'
              }
            }
          },
          {path: '/product/search', component: ProductSearch, name: 'ProductSearch', meta: {title: '米诺·产品搜索'}}
        ]
      },
      {
        path: '/video',
        component: Video,
        name: 'Video',
        meta: {
          title: {en: 'Minoo - Video', zh: '米诺视频'},
          keywords: {en: 'WELDING， MIG、MAG，TIG，PLASMA， Video', zh: '焊接视频，焊接技术'},
          description: {en: 'WELDING， MIG、MAG，TIG，PLASMA， Video', zh: '焊接视频，焊接技术'}
        }
      },
      {
        path: '/partner',
        component: Partner,
        name: 'Partner',
        meta: {
          title: {en: 'Minoo - Partner', zh: '米诺·伙伴'},
          keywords: {en: 'WELDING Partner', zh: '焊接合作伙伴'},
          description: {en: 'WELDING Partner', zh: '焊接合作伙伴'}
        }
      },
      {
        path: '/dealer',
        component: Dealer,
        name: 'Dealer',
        meta: {
          title: {en: 'Minoo - Dealer', zh: '米诺·供应商'},
          keywords: {en: 'WELDING Dealer Policy', zh: '焊接品牌代理'},
          description: {en: 'WELDING Dealer Policy', zh: '焊接品牌代理'}
        }
      },
      {
        path: '/about',
        component: JoinUs,
        name: 'JoinUs',
        meta: {
          title: {en: 'Minoo - AboutUs', zh: '米诺·关于我们'},
          keywords: {en: 'What About Us', zh: '关于我们'},
          description: {en: 'What About Us', zh: '关于我们'}
        }
      }
    ]
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({routes})
router.beforeEach((to, from, next) => {
  if (LanguageUtils.justifyLanguage() === 'ENGLISH') {
    window.document.title = ld.get(to, 'meta.title.en', 'MINOO TORCH')
    const keyword = document.querySelector('meta[name="keywords"]')
    keyword.content = ld.get(to, 'meta.keywords.en', 'MINOO TORCH')
    const desc = document.querySelector('meta[name="description"]')
    desc.content = ld.get(to, 'meta.description.en', 'MINOO TORCH')
  } else {
    window.document.title = to.meta.title.zh
    const keyword = document.querySelector('meta[name="keywords"]')
    keyword.content = ld.get(to, 'meta.keywords.zh', 'MINOO TORCH')
    const desc = document.querySelector('meta[name="description"]')
    desc.content = ld.get(to, 'meta.description.zh', 'MINOO TORCH')
  }
  console.log()
  document.documentElement.scrollTop = 0
  next()
})

export default router
