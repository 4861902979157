<template>
  <div class="copy-right-container">
    <div class="wrap">
      <el-divider></el-divider>
      <e-title class="text-color" :content="description"></e-title>
      <e-title class="text-color" :content="phase"></e-title>
      <span><a href="https://beian.miit.gov.cn/" target="_blank" class="text-color" style="text-align: center;">沪ICP备09070183号-1</a></span>

    </div>
  </div>
</template>

<script>
  import ETitle from '@/components/title'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {CopyRightService} from '@/service/FrameService'

  const service = new CopyRightService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.FOOTER,
    SpeciesEnum.MI,
    LayoutEnum.INDEX
  )
  export default {
    name: 'copy-right',
    components: {
      ETitle
    },
    data () {
      return {
        service,
        description: '米诺焊接 版权所有',
        phase: '@2007 - 2027'
      }
    },
    methods: {
      getItem () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          item = item || {}
          this.description = item.field1
          this.phase = item.field2
        }
        this.service.first(options)
      }
    },
    created () {
      this.getItem()
    }
  }
</script>

<style scoped>

  .copy-right-container {
    height: 133px;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }

  /deep/ .el-divider {
    background-color: var(--main-theme-color);
  }

  .text-color {
    padding: 0;
    height: 40px;
    line-height: 40px;
    letter-spacing: normal;
    font-size: 24px;
    color: var(--main-theme-color);
  }

</style>
