<template>
  <div class="product-list-container">
    <router-link class="item" v-for="item in items" :key="item.id" :to="{path: `/product/detail/${item.id}`}">
      <el-card class="card" shadow="hover" :body-style="{padding:0}">
        <img class="cover" :src="item.thumb">
        <span class="title">
            {{item.title}}</span>
        <el-divider class="divider-color divider-width"></el-divider>
        <ul class="content">
          <li class="description" v-for="des in item.descriptions" :key="des.id">
            <div class="content-wrap">
              <img :src="des.thumb" :style="{width: des.title + 'px', height: des.title + 'px'}">
              <div class="span">{{des.description}}</div>
            </div>
          </li>
        </ul>
      </el-card>
    </router-link>
  </div>
</template>

<script>
  import Card from '@/components/card'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'
  import {ProductService} from '@/service/ProductService'
  import {cloneDeep, merge} from 'lodash'

  const service = new ProductService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.PRODUCT,
    SpeciesEnum.MI,
    LayoutEnum.COVER
  )
  export default {
    name: 'ProductList',
    components: {
      Card
    },
    data () {
      return {
        service,
        cardHovered: false,
        id: this.$route.params.id || -1,
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = merge(cloneDeep(params), {id: this.id})
        options.success = ({items}) => {
          this.items = items || []
          this.items.forEach(e => {
            e.description = e.description.split('\n')
          })
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  a {
    text-decoration: none;
  }

  .title-underline {
    padding-bottom: 4px;
    border-bottom: 1px solid var(--main-theme-thin-color);
  }

  .product-list-container {
    width: 100%;
    display: flex;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
  }

  .item {
    margin: 0 6px;
  }

  .card {
    margin-bottom: 20px;
    width: 300px;
    border-color: var(--main-theme-thin-color);
  }

  .card:hover {
    /*box-shadow: 0 2px 12px 0 rgb(245 178 141) !important;*/
    box-shadow: 0 4px 16px 0 #eb6100 !important;
  }

  .divider-color {
    margin: 0 auto;
    background-color: var(--main-theme-thin-color);
  }

  .divider-width {
    width: 80%;
    height: 2px;
    margin: 12px auto;
  }


  .cover {
    width: 300px;
  }

  .title {
    width: 100%;
    margin: 0 auto;
    display: inline-block;
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
  }


  .description {
    /*padding-left: 10%;*/
    margin-top: 6px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .content {
    width: inherit;
    margin: 20px auto;

  }

  .description .content-wrap {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

  }

  .description img {
    margin-right: 12px;
  }

  .description i {
    width: 20%;
    display: inline-block;
  }

  .description .span {
    font-size: 16px;
    font-weight: bold;
  }

</style>
