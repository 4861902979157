<template>
  <div class="join-us-container">
    <router-link :to="{path: '/about'}">
      <div class="wrap">
        <e-title :content="title.field1 || ''"></e-title>
        <img :src="item.href">
      </div>
    </router-link>
  </div>
</template>

<script>
  import ETitle from '@/components/title'
  import {DealerService, TitleService} from '@/service/IndexService'
  import {DefaultParams, Options} from '@/service/BaseService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import {LayoutEnum, SectionEnum, SpeciesEnum} from '@/enumeration/OptionsEnum'

  const service = new DealerService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.MI,
    LayoutEnum.JOIN_US
  )
  export default {
    name: 'JoinUs',
    components: {
      ETitle
    },
    data () {
      return {
        service,
        titleService,
        title: {},
        item: {}
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.item = item || {}
        }
        this.service.first(options)
      }
    },
    created () {
      this.getTitle()
      this.getData()
    }
  }
</script>

<style scoped>
  .join-us-container {
    height: 610px;
  }

  .wrap {
    margin: 0 auto;
    width: calc(var(--inner-width) * 1px);
  }

  .wrap img {
    width: 100%;
    height: 480px;
    object-fit: cover;
  }
</style>
