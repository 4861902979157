<template>
  <div class="aside-container">
    <div class="wrap" v-for="item in items" :key="item.id">
      <span :class="{'english-title-style':isEnglish}">{{item.title}}</span>
      <ul>
        <div class="content">
          <li v-for="sub in item.items" :key="sub.id">
            <router-link ref="category" :to="{path:`/product/list/${sub.id}`}" active-class="is-active">{{sub.title}}
            </router-link>
          </li>
        </div>
      </ul>
    </div>
    <div class="search">
      <div class="info-box" @click="enterSearch">
        <img :src="searchIcon">
        <span>{{searchNotification}}</span>
      </div>
      <input type="text" v-model="searchKeyword" @keyup.enter.stop.self="enterSearch">
    </div>
  </div>
</template>

<script>
  import {LanguageEnum} from '@/enumeration/OptionsEnum'
  import LanguageUtils from '@/utils/LanguageUtils'
  import EventBusNamespace from '@/bus'

  export default {
    name: 'Aside',
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    computed: {
      isEnglish () {
        return LanguageUtils.justifyLanguage() === LanguageEnum.ENGLISH
      },
      searchNotification () {
        const words = {
          [LanguageEnum.ENGLISH]: 'Please Enter The Number',
          [LanguageEnum.CHINESE]: '请输入编号'
        }
        return words[LanguageUtils.justifyLanguage()]
      }
    },
    data () {
      return {
        searchKeyword: '',
        searchIcon: require('@img/search_icon.png')
      }
    },
    methods: {
      enterSearch () {
        this.$router.push({path: '/product/search', query: {searchKeyword: this.searchKeyword}})
        this.$bus.$emit(EventBusNamespace.SEARCH, this.searchKeyword)
      }
    }
  }
</script>

<style scoped>
  .aside-container {
    width: 280px !important;
  }

  .wrap {
    width: 280px;
  }

  .wrap span {
    margin: 0 auto;
    width: inherit;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: var(--main-theme-color);
    text-align: center;
  }

  .wrap .content {
    width: inherit;
    display: block;
    border: none;
    overflow: hidden;
    border-radius: 4px;

  }


  .wrap li {
    width: inherit;
    height: 40px;
    line-height: 40px;

    font-size: 20px;
    text-align: center;
    background-color: #e2e2e2;
    cursor: pointer;
  }

  .wrap li:hover {
    color: var(--main-theme-color);
  }

  .is-active {
    color: var(--main-theme-color);
    background-color: #bcbcbc;
  }

  .search {
    margin-top: 40px;
    cursor: pointer;
  }

  .info-box {
    display: flex;
    height: 30px;
    justify-content: center;
    align-content: center;
    font-size: 24px;
  }

  .info-box img {
    height: 100%;
  }

  .info-box span {
    height: 30px;
    line-height: 30px;
  }

  .search input {
    margin-top: 20px;
    padding: 0 8px;
    height: 40px;
    width: calc(100% - 16px);
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    border: 1px solid var(--main-theme-thin-color);
    outline-color: var(--main-theme-thin-color);
    color: var(--main-theme-thin-color);
  }

  .search input::selection {
    background-color: var(--main-theme-thin-color);
    color: white;
  }


  .english-title-style {
    color: white !important;
  }

</style>
